import React, { useCallback, useContext, useState } from 'react';
import { LineageContext } from '../../hooks/useVariation';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { Button } from 'react-bootstrap';

export function SigningConfigurationNextButton({
  prepareForSigning,
  label = 'Submit for Signing'
}: {
  prepareForSigning: () => Promise<void> | void,
  label?: string
}) {
  const [waiting, setWaiting] = useState(false);
  const { snapshotData } = useContext(LineageContext);
  const { focusErrList } = useContext(WizardDisplayContext);
  const onClick = useCallback(() => {
    setWaiting(true);
    Promise.resolve(prepareForSigning())
      .catch(e=>{console.error(); throw e;})
      .finally(() => setWaiting(false));
  }, [snapshotData]);

  return <div className="d-flex flex-column gap-1 align-items-end">
    <Button
      key="button"
      disabled={waiting || focusErrList.length !== 0}
      onClick={onClick}>
      {label}
    </Button>
  </div>;
}
